









































import { Component, Vue, Prop } from 'vue-property-decorator';

import ExLoading from '@/globalComponents/ExLoading/index.vue';
import Accordion from '@/components/Accordion/index.vue';

import SimulationService from '@/services/Simulation/SimulationService';

import { InstanceAmplitude } from '@/share/Util/Metrics/Metrics';

import {
  Simulated as ISimulated,
  SimulatedWithChildren as ISimulatedWithChildren
} from '../interface/IOtherSimulatedAccordion';

@Component({
  name: 'ContainerAccordionSimulated',
  components: {
    ExLoading,
    Accordion
  }
})
export default class ContainerAccordionSimulated extends Vue {
  @Prop() simulatedData!: Array<ISimulated>;
  @Prop({ default: '1' }) indexAccordion!: string;
  @Prop({ default: 10 }) padding!: number;

  private simulatedChildData: Array<ISimulated> = [];
  private isLoading = false;
  private ServiceSimulation = new SimulationService();

  get isDarkMode() {
    return this.$store.getters.isDarkMode;
  }

  setHash() {
    return Math.floor(Math.random() * (40 - 0)) + 0;
  }

  verifySimulatedDay(title: string) {
    return title.toUpperCase().indexOf('DIA') !== -1;
  }

  verifySimulatedAccordionLimit(simulatedData: ISimulatedWithChildren) {
    let idSimulated = 0;
    simulatedData.children.forEach((simulated: ISimulated) => {
      if (this.verifySimulatedDay(simulated?.title)) {
        idSimulated = idSimulated || simulated?.id;
      }
    });
    return idSimulated;
  }

  async verifySimulatedChildren(id: number, title: string) {
    try {
      this.isLoading = true;
      const responseChildren = await this.ServiceSimulation.getChildrenSimulated(id);

      const idSimulatedLimit = this.verifySimulatedAccordionLimit(responseChildren);

      if (idSimulatedLimit) {
        this.goToSimulated(idSimulatedLimit, title);
        return;
      }
      this.setOrderingSimulated(responseChildren);
    } catch (error) {
      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao carregar lista de simulados.',
        status: 'error'
      });
      console.error(error);
    } finally {
      this.isLoading = false;
    }
  }

  setOrderingSimulated(simulated: ISimulatedWithChildren) {
    this.simulatedChildData = simulated.children.sort(
      (simulatedA: ISimulated, simulatedB: ISimulated) => (simulatedA?.title < simulatedB?.title ? -1 : 0)
    );
    this.setTitleSimulated();
  }

  setTitleSimulated() {
    this.simulatedChildData = this.simulatedChildData.map((simulated: ISimulated) => ({
      ...simulated,
      title: simulated.title.replace('°', 'ª')
    }));
  }

  goToSimulated(idSimulated: number, title?: string) {
    this.setTrackAmplitude(idSimulated, title);

    this.$router.push({
      name: 'SimulatedInstruction',
      params: {
        id: `${idSimulated}`
      }
    });
  }

  setTrackAmplitude(simulatedID: number, nameLocal?: string) {
    InstanceAmplitude.setTrack({
      eventName: 'component_click',
      additionalAttrs: {
        data: {
          component: 'ContainerAccordionSimulated',
          local: nameLocal || '',
          simulatedID
        }
      }
    });
  }
}
